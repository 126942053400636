import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";
import garowaLogo from "./assets/images/garowa-logo.png";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <img style={{ width: 70 }} src={garowaLogo} alt="Logotipo Garowa" />
          </div>
          <div className="social">
            <a href="https://www.instagram.com/garowa.music/" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Bem-vindo à Garowa Platform</h1>
            <p>Em Breve - Sua plataforma para criadores de música e entusiastas.</p>
          </div>
          <a href="https://linktr.ee/garowaco">
            <div className="cta">Ver linktree</div>
          </a>
        </div>
        <div className="footer">
          <span>Uma empresa de <a className="underlined" href="https://www.garowa.co/" target="_blank" rel="noopener noreferrer">Garowa Co.</a> </span>
        </div>
      </div>
    );
  }
}

export default App;
